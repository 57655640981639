/* Bootstrap overrides
-------------------------------------------------- */

$body-bg: #161619;
$body-color: rgb(255, 255, 255);
$headings-font-weight: 300 !important;
$primary: #1b46c2;
$light: #fafafa;

$headings-font-weight: 300 !important;
$a: #1392ff;

body {
  background-image: linear-gradient(180deg, #2c2678 0%, #430a6b 100%);
}
/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';

.custom-classname.btn-primary {
  background-color: green;
}
/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $black;

      &:hover {
        color: $black;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}

//toasts
.custom-class-for-modals {
  background: rgb(27, 0, 52) !important;
}
.modal .modal-content  {
  background: rgb(27, 0, 52);
}
.dapp-unlock-page .progress {
  background-color: rgb(57, 57, 57) !important;
}
.dapp-unlock-page .modal-content {
  background-color: rgb(57, 57, 57) !important;
  #closeButton {
    color: rgb(155, 155, 155) !important;
  }
}

#dapp-modal div {
  background-color: rgb(57, 57, 57) !important;
  button {
    color: rgb(155, 155, 155) !important;
    background-color: inherit;
  }
  #closeButton {
    color: rgb(155, 155, 155) !important;
  }
  strong {
    color: $yellow !important;
  }
  .fa-lock {
    color: $yellow !important;
  }
  a {
    color: $yellow !important;
  }
}

.dapp-unlock-page .card {
  color: white !important;
  background: rgb(27, 0, 52) !important;
  backdrop-filter: blur(1px) !important;
  button,
  a {
    background-color: $yellow !important;
    color: black !important;
    outline: none !important;
    border: none !important;
    &:hover,
    &:active {
      background-color: darken($yellow, 5%) !important;
      color: white !important;
    }
  }
}

.dapp-unlock-page div {
  color: white !important;
  background-color: rgb(27, 0, 52) !important;
  backdrop-filter: blur(1px) !important;
  button,
  a {
    background-color: $yellow !important;
    color: black !important;
    outline: none !important;
    border: none !important;
    &:hover,
    &:active {
      background-color: darken($yellow, 5%) !important;
      color: white !important;
    }
  }
}

.modal-dialog-scrollable {
  .modal-body {
    overflow-x: hidden !important;
  }
}
