@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.nft-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $warning;
  border-radius: 10px;
  padding-bottom: 10px;
  color: black;
  height: 100%;

  img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 5px;
  }

  .nft-tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }

  &.selected {
    background: darken($yellow, 5%);
    box-shadow: 0 0 30px 1px #ffc107;
    outline: 3px solid white;
  }

  &:hover {
    background: lighten($yellow, 5%);
    box-shadow: 0 0 8px 1px #ffc107;
  }
}
