@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.dashboard {
  .accordion-button {
    all: unset;
  }

  // .stake-type {
  //   display: flex;
  //   justify-content: center;
  //   button {
  //     width: 50%;
  //     height: 300px;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 2em;
  //     border-radius: 20px;
  //     i {
  //       font-size: 5rem;
  //     }
  //   }
  // }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.211);
  }
  .booster-row {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-top: 5px;
  }
  .card-wrapper {
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 1em;
    padding: 1em;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.156);

    button {
      font-weight: 500;
    }
  }

  .add-nft-button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $yellow;
    border-radius: 20px;
    color: $yellow;
    flex-direction: column;
    font-size: 1.2em;
    font-weight: 500;

    &:hover {
      color: darken($yellow, 5%);
      border: 2px solid darken($yellow, 5%);
      cursor: pointer;
    }
  }
}
